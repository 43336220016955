import { useEffect, useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { FormaPago } from "../../api/models/satModels";
import { getFormasPago } from "../../api/services/satServices";
import ErrorModal from "../ErrorModal";
import LoadingOverlay from "../LoadingOverlay";

interface Props {
  SAT_c_FormaPago: string | undefined;
  onChange: (SAT_c_FormaPago: string) => void;
  isInvalid?: boolean;
}
const FormaPagoCombo = (props: Props) => {
  const [formasPago, setFormasPago] = useState<FormaPago[]>();
  const [showError, setShowError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    cargarFormasPago();
  }, []);

  const cargarFormasPago = async () => {
    try {
      setShowError(false);
      let formasPago = await getFormasPago();
      setFormasPago(formasPago);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };
  if (loading)
    return (
      <LoadingOverlay show={true}>
        <div className="fs-6 text-center">
          <p className="mb-1">Cargando Formas de pago del SAT</p>
          <p className="mb-1">Por favor espere ...</p>
        </div>
      </LoadingOverlay>
    );

  if (showError)
    return (
      <ErrorModal
        buttonText="Reintentar"
        onClick={async () => cargarFormasPago()}
        showError={showError}
      >
        <p>
          Ocurrió un error al obtener el catálogo de Formas de pago del SAT.
        </p>
        <p>Por favor intente de nuevo en unos minutos.</p>
      </ErrorModal>
    );
  return (
    <Form.Group id="FormaPago" className="mt-2">
      <FloatingLabel label="Forma de pago">
        <Form.Select
          placeholder="Forma de pago"
          isInvalid={props.isInvalid}
          value={
            props.SAT_c_FormaPago == null ? undefined : props.SAT_c_FormaPago
          }
          onChange={(e) => props.onChange(e.currentTarget.value)}
        >
          <option>Seleccione la forma de pago</option>
          {formasPago?.map((u, i) => (
            <option key={i} value={u.claveFormaPago}>
              {u.claveFormaPago} {u.descripcion}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  );
};

export default FormaPagoCombo;
