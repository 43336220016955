import { Modal, Spinner } from "react-bootstrap";

interface Props {
  show: boolean;
  message?: string;
  children?: React.ReactNode;
}

const LoadingOverlay = ({ show = true, message, children }: Props) => {
  return (
    <Modal show={show} centered backdrop="static">
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" role="status" />

        {message && <div className="mt-2">{message}</div>}
        {children && <div className="mt-2">{children}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default LoadingOverlay;
