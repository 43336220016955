import { useEffect, useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { UsoCFDI } from "../../api/models/satModels";
import { getUsosCFDI } from "../../api/services/satServices";
import ErrorModal from "../ErrorModal";
import LoadingOverlay from "../LoadingOverlay";

interface Props {
  SAT_c_UsoCFDI: string | undefined;
  onChange: (SAT_c_UsoCFDI: string) => void;
  isInvalid?: boolean;
}

const UsoCDFICombo = (props: Props) => {
  const [usosCFDI, setUsosCFDI] = useState<UsoCFDI[]>();
  const [showError, setShowError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    cargarUsos();
  }, []);

  const cargarUsos = async () => {
    try {
      setShowError(false);
      let usosCFDI = await getUsosCFDI();
      setUsosCFDI(usosCFDI);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <LoadingOverlay show={true}>
        <div className="fs-6 text-center">
          <p className="mb-1">Cargando Usos CFDI del SAT</p>
          <p className="mb-1">Por favor espere ...</p>
        </div>
      </LoadingOverlay>
    );

  if (showError)
    return (
      <ErrorModal
        showError={showError}
        buttonText="Reintentar"
        onClick={async () => cargarUsos()}
      >
        <p>Ocurrió un error al obtener el catálogo de Uso CFDI del SAT.</p>
        <p>Por favor intente de nuevo en unos minutos.</p>
      </ErrorModal>
    );

  return (
    <Form.Group id="UsoCFDI" className="mt-2">
      <FloatingLabel label="Uso CFDI">
        <Form.Select
          placeholder="Uso CFDI"
          isInvalid={props.isInvalid}
          value={props.SAT_c_UsoCFDI == null ? undefined : props.SAT_c_UsoCFDI}
          onChange={(e) => props.onChange(e.currentTarget.value)}
        >
          {usosCFDI?.map((u, i) => (
            <option key={i} value={u.c_UsoCFDI}>
              {u.c_UsoCFDI} {u.descripcion}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  );
};

export default UsoCDFICombo;
