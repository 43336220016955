import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DatosRemision } from "../api/models/datosRemisionModel";
import { validationError } from "../api/models/error";
import { validateRemisionPorFacturarParams } from "../api/services/invoiceServices";

interface RemisionAFacturarProps {
  subscriberId: string;
}

/*
 Pide al usuario la remision, webId y rfc
 Si captura todos los datos, redirecciona a /facturarRemision y pasa estos datos como parámetros
*/
const RemisionAFacturar = ({ subscriberId }: RemisionAFacturarProps) => {
  const [datosRemision, setDatosRemision] = useState<DatosRemision>({
    folioRemision: "",
    webId: "",
    rfc: "",
  });
  const [currentError, setCurrentError] = useState<validationError>();

  const [modalError, setModalError] = useState<string>();

  const navigate = useNavigate();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setDatosRemision({ ...datosRemision, [name]: value.toLocaleUpperCase() });
  };

  const handleSave = async () => {
    if (!validate()) return;

    const params = new URLSearchParams();
    params.append("folioRemision", datosRemision.folioRemision);
    params.append("webId", datosRemision.webId);
    params.append("rfc", datosRemision.rfc);
    const queryString = params.toString();

    navigate(`facturarRemision?${queryString}`);
  };

  const validate = () => {
    setCurrentError(undefined);

    const validationError = validateRemisionPorFacturarParams(
      datosRemision.folioRemision,
      datosRemision.webId,
      datosRemision.rfc
    );

    if (validationError.valid) return true;

    setCurrentError(validationError);
    return false;
  };

  return (
    <div className="panel">
      <span className="fs-3 text-center">Remisión a facturar</span>
      <Form className="p-4">
        <Form.Group>
          <Form.Label>Remisión:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Folio de la remisión a facturar"
            name="folioRemision"
            value={datosRemision.folioRemision}
            onChange={handleInputChange}
            isInvalid={currentError?.property === "folioRemision"}
          />
        </Form.Group>

        <Form.Group className="mt-2">
          <Form.Label>WebId:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Identificador de la remisión a facturar"
            name="webId"
            value={datosRemision.webId}
            onChange={handleInputChange}
            isInvalid={currentError?.property === "webId"}
          />
        </Form.Group>

        <Form.Group className="mt-2">
          <Form.Label>RFC:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Escriba su RFC"
            name="rfc"
            value={datosRemision.rfc}
            onChange={handleInputChange}
            isInvalid={currentError?.property === "rfc"}
          />
        </Form.Group>
      </Form>

      {currentError && <Alert variant="danger">{currentError?.message}</Alert>}
      <div className="d-flex justify-content-end">
        <Button className="flex-grow-1" variant="primary" onClick={handleSave}>
          Continuar
        </Button>
      </div>

      <Modal show={!!modalError} onHide={() => setModalError(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalError}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => setModalError(undefined)}
          >
            Aceptar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RemisionAFacturar;
