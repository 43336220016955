import { Button, Modal } from "react-bootstrap";

interface Props {
  showError: boolean;
  children?: React.ReactNode;
  buttonText: string;
  onClick: () => void;
}
const ErrorModal = ({
  showError = true,
  children,
  buttonText = "Aceptar",
  onClick,
}: Props) => {
  return (
    <Modal show={showError}>
      <Modal.Header>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>{buttonText}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
