import axios from "axios";
import { getGlobalAPIUrl } from "../helpers/apiHelpers";
import { UsoCFDI } from "../models/satModels";
import { RegimenFiscal } from "../../api/models/satModels";
import { FormaPago } from "../../api/models/satModels";

export const getUsosCFDI = async (): Promise<UsoCFDI[]> => {
  try {
    const response = await axios.get(getGlobalAPIUrl("CatalogosSAT/UsoCFDI"));
    return response.data;
  } catch (error) {
    console.log("err", error);
    throw error;
  }
};

export const getRegimenesFiscales = async (): Promise<RegimenFiscal[]> => {
  try {
    const response = await axios.get(
      getGlobalAPIUrl("CatalogosSAT/RegimenFiscal")
    );
    const r = response.data as RegimenFiscal[];
    return r.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
  } catch (error) {
    console.log("err", error);
    throw error;
  }
};

export const getFormasPago = async (): Promise<FormaPago[]> => {
  try {
    const response = await axios.get(getGlobalAPIUrl("CatalogosSAT/FormaPago"));
    const r = response.data as FormaPago[];
    return r.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
  } catch (error) {
    console.log("err", error);
    throw error;
  }
};
