import { ReactNode, useEffect, useState } from "react";
import { Alert, Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { remisionPorFacturar } from "../api/models/datosFacturacionModel";
import { validationError } from "../api/models/error";
import {
  CrearFacturaRemision,
  getRemisionPorFacturar,
  validateDatosFacturacion,
} from "../api/services/invoiceServices";
import FormaPagoCombo from "../components/sat/FormaPagoCombo";
import LoadingOverlay from "../components/LoadingOverlay";
import RegimenFiscalCombo from "../components/sat/RegimenFiscalCombo";
import UsoCDFICombo from "../components/sat/UsoCFDIcombo";
import ErrorModal from "../components/ErrorModal";

import { QuestionCircle } from "react-bootstrap-icons";
import InfoModal from "../components/InfoModal";

interface FacturarRemisionProps {
  subscriberId: string;
}

type MyError = {
  isFatal: boolean;
  message: string;
  idFactura?: string;
  errorCode?: number;
};

const FacturarRemision = ({ subscriberId }: FacturarRemisionProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const folioRemision = searchParams.get("folioRemision");
  const webId = searchParams.get("webId");
  const rfc = searchParams.get("rfc");

  const [currentError, setCurrentError] = useState<MyError>();
  const [validationErrors, setValidationErrors] = useState<validationError[]>(
    []
  );

  const [remisionPorFacturar, setRemisionPorFacturar] =
    useState<remisionPorFacturar>();

  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(
    "Cargando la remisión"
  );

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [infoTitle, setInfoTitle] = useState<string>("");
  const [infoContent, setInfoContent] = useState<ReactNode>();

  useEffect(() => {
    async function cargarRemisionAFacturar() {
      // Si no recibió los tres parámetros (folio, webId y rfc) manda a la captura de la remisión a facturar
      if (!folioRemision || !webId || !rfc) {
        navigate("/sdm");
        return;
      }

      // Obtiene la remision
      try {
        let response = await getRemisionPorFacturar(
          subscriberId,
          folioRemision,
          webId,
          rfc
        );

        const remision = response as remisionPorFacturar;

        if (remision.Estatus === 0) {
          remision.DatosFacturacion.SAT_c_UsoCFDI =
            remision.Remision.SAT_c_UsoCFDI;
          remision.DatosFacturacion.SAT_c_FormaPago =
            remision.Remision.SAT_c_FormaPago;

          setRemisionPorFacturar(remision);

          return;
        }

        if (remision.Estatus === 4) {
          setCurrentError({
            isFatal: true,
            message: remision.EstatusDescripcion,
            errorCode: remision.Estatus,
            idFactura: remision.idFactura,
          });
          return;
        }
        setCurrentError({
          isFatal: true,
          message: remision.EstatusDescripcion,
        });
      } catch (error) {
        if (error instanceof Error)
          setCurrentError({ isFatal: true, message: error.message });
        else
          setCurrentError({
            isFatal: true,
            message: "Ocurrió un error inesperado",
          });
      } finally {
        setLoadingMessage(undefined);
      }
    }

    // El API valida la remision a facturar
    cargarRemisionAFacturar();
  }, []);

  const handleSave = async () => {
    if (!remisionPorFacturar) return;
    const validation = validateDatosFacturacion(remisionPorFacturar);

    if (validation.length > 0) {
      setValidationErrors(validation);
      return;
    }
    setValidationErrors([]);

    try {
      setLoadingMessage("Generando su factura");
      const response = await CrearFacturaRemision(
        remisionPorFacturar,
        subscriberId
      );

      const params = new URLSearchParams();
      params.append("folio", response.Folio);
      params.append("urlPDF", response.urlPDF);
      params.append("urlXML", response.urlXML);
      navigate(`/${subscriberId}/descargarFactura?${params.toString()}`);
    } catch (error) {
      console.log("err", error);
      if (error instanceof Error)
        setCurrentError({ isFatal: false, message: error.message });
      else
        setCurrentError({
          isFatal: false,
          message:
            "Ocurrió un error inesperado al generar la factura. Por favor intente nuevamente.",
        });
    } finally {
      setLoadingMessage(undefined);
    }
  };

  const handleDescargarFactura = () => {
    if (!currentError || !currentError?.idFactura) return;
    const params = new URLSearchParams();
    params.append("idFactura", currentError.idFactura);
    navigate(`/${subscriberId}/descargarFactura?${params.toString()}`);
  };

  // Muestra error y regresa a RemisionAFacturar
  if (currentError) {
    return (
      <ErrorModal
        showError={true}
        buttonText="Aceptar"
        onClick={() =>
          currentError.isFatal
            ? navigate(`/${subscriberId}`)
            : setCurrentError(undefined)
        }
      >
        {currentError.message}
        <div className="d-flex mt-4">
          {/*  {currentError?.errorCode === 4 && (
            <Button variant="success" onClick={handleDescargarFactura}>
              Descargar la factura
            </Button>
          )} */}
        </div>
      </ErrorModal>
    );
  }

  // Cargando
  if (loadingMessage || !remisionPorFacturar)
    return (
      <LoadingOverlay show={true}>
        <div className="fs-6 text-center">
          <p className="mb-1">
            {loadingMessage ? loadingMessage : "Cargando la remisión"}
          </p>
          <p className="mb-1">Por favor espere ...</p>
        </div>
      </LoadingOverlay>
    );

  return (
    <div>
      <div className="panel">
        <span className="fs-3 text-center">Datos de facturación</span>
        <Form className="p-1">
          <div className="d-flex gap-3 justify-content-between">
            <Form.Group>
              <Form.Label>
                Remisión <strong>{remisionPorFacturar.Remision.Folio}</strong>
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                RFC <strong>{remisionPorFacturar.DatosFacturacion.RFC}</strong>
              </Form.Label>
            </Form.Group>
          </div>

          <Form.Group id="Nombre" className="mt-2 d-flex align-items-center">
            <FloatingLabel label="Nombre" className="flex-grow-1">
              <Form.Control
                type="text"
                isInvalid={
                  validationErrors.findIndex((x) => x.property === "Nombre") >
                  -1
                }
                value={remisionPorFacturar.DatosFacturacion.Nombre}
                onChange={(e) => {
                  let value = e.currentTarget.value.toUpperCase();
                  setRemisionPorFacturar((prevState) => {
                    if (!prevState) {
                      return prevState;
                    }
                    return {
                      ...prevState,
                      DatosFacturacion: {
                        ...prevState.DatosFacturacion,
                        Nombre: value,
                      },
                    };
                  });
                }}
              />
            </FloatingLabel>
            <QuestionCircle
              className="ms-2 text-primary"
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setShowInfo(true);
                setInfoTitle("Nombre del Receptor");
                setInfoContent(
                  <div>
                    <p>
                      Escriba el nombre exactamente como aparece en su{" "}
                      <strong>Cédula de Identificación Fiscal</strong>.
                    </p>
                    <p>
                      Para personas físicas: <i>Nombre + Apellidos</i>
                    </p>
                    <p>
                      Para personas morales es importante omitir el{" "}
                      <strong>régimen societario</strong> (S.A. de C.V, S.C.,
                      etc)
                    </p>
                    <p>
                      En general no deberá usar acentos, a menos que su Cédula
                      de Identificación Fiscal tenga acentos.
                    </p>
                    <p>
                      Cualquier diferencia con su{" "}
                      <strong>Cédula de Identificación Fiscal</strong> hará que
                      el SAT rechace el timbrado de su facture.
                    </p>
                  </div>
                );
              }}
            />
          </Form.Group>

          <div className="mt-2 d-flex align-items-center">
            <RegimenFiscalCombo
              RegimenFiscal={remisionPorFacturar.DatosFacturacion.RegimenFiscal}
              isInvalid={
                validationErrors.findIndex(
                  (x) => x.property === "RegimenFiscal"
                ) > -1
              }
              onChange={(value) => {
                setRemisionPorFacturar((prevState) => {
                  if (!prevState) {
                    return prevState;
                  }
                  return {
                    ...prevState,
                    DatosFacturacion: {
                      ...prevState.DatosFacturacion,
                      RegimenFiscal: value,
                    },
                  };
                });
              }}
            />
            <QuestionCircle
              className="ms-2 text-primary"
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setShowInfo(true);
                setInfoTitle("Régimen Fiscal");
                setInfoContent(
                  <div>
                    <p>
                      Seleccione el régimen fiscal como aparece en su{" "}
                      <strong>Cédula de Identificación Fiscal</strong>.
                    </p>

                    <p>
                      Cualquier diferencia con su{" "}
                      <strong>Cédula de Identificación Fiscal</strong> hará que
                      el SAT rechace el timbrado de su facture.
                    </p>
                  </div>
                );
              }}
            />
          </div>

          <Form.Group id="Calle" className="mt-2">
            <FloatingLabel label="Calle">
              <Form.Control
                type="text"
                placeholder="Nombre de la calle"
                value={remisionPorFacturar.DatosFacturacion.Calle}
                onChange={(e) => {
                  let value = e.currentTarget.value.toUpperCase();
                  setRemisionPorFacturar((prevState) => {
                    if (!prevState) {
                      return prevState;
                    }
                    return {
                      ...prevState,
                      DatosFacturacion: {
                        ...prevState.DatosFacturacion,
                        Calle: value,
                      },
                    };
                  });
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <div id="NoExterior_NoInterior" className="d-flex gap-3 mt-2">
            <Form.Group>
              <FloatingLabel label="No. Exterior">
                <Form.Control
                  type="text"
                  placeholder="No. Exterior"
                  value={remisionPorFacturar.DatosFacturacion.NoExterior}
                  onChange={(e) => {
                    let value = e.currentTarget.value.toUpperCase();
                    setRemisionPorFacturar((prevState) => {
                      if (!prevState) {
                        return prevState;
                      }
                      return {
                        ...prevState,
                        DatosFacturacion: {
                          ...prevState.DatosFacturacion,
                          NoExterior: value,
                        },
                      };
                    });
                  }}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label="No. Interior">
                <Form.Control
                  type="text"
                  placeholder="No. Interior"
                  value={remisionPorFacturar.DatosFacturacion.NoInterior}
                  onChange={(e) => {
                    let value = e.currentTarget.value.toUpperCase();
                    setRemisionPorFacturar((prevState) => {
                      if (!prevState) {
                        return prevState;
                      }
                      return {
                        ...prevState,
                        DatosFacturacion: {
                          ...prevState.DatosFacturacion,
                          NoInterior: value,
                        },
                      };
                    });
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </div>

          <div id="CodigoPostal_Colonia" className="d-flex gap-3 mt-2 ">
            <Form.Group className="mt-2 d-flex align-items-center">
              <FloatingLabel label="Código Postal">
                <Form.Control
                  type="text"
                  placeholder="Código Postal"
                  isInvalid={
                    validationErrors.findIndex(
                      (x) => x.property === "CodigoPostal"
                    ) > -1
                  }
                  value={remisionPorFacturar.DatosFacturacion.CodigoPostal}
                  onChange={(e) => {
                    let value = e.currentTarget.value.toUpperCase();
                    setRemisionPorFacturar((prevState) => {
                      if (!prevState) {
                        return prevState;
                      }
                      return {
                        ...prevState,
                        DatosFacturacion: {
                          ...prevState.DatosFacturacion,
                          CodigoPostal: value,
                        },
                      };
                    });
                  }}
                />
              </FloatingLabel>
              <QuestionCircle
                className="ms-2 text-primary"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setShowInfo(true);
                  setInfoTitle("Código Postal");
                  setInfoContent(
                    <div>
                      <p>
                        Escriba el Código Postal de su domicilio fiscal que
                        tiene registrado en su{" "}
                        <strong>Cédula de Identificación Fiscal</strong>.
                      </p>

                      <p>
                        Cualquier diferencia con su{" "}
                        <strong>Cédula de Identificación Fiscal</strong> hará
                        que el SAT rechace el timbrado de su facture.
                      </p>
                    </div>
                  );
                }}
              />
            </Form.Group>
            <Form.Group className="flex-grow-1">
              <FloatingLabel label="Colonia">
                <Form.Control
                  type="text"
                  placeholder="Colonia"
                  value={remisionPorFacturar.DatosFacturacion.Colonia}
                  onChange={(e) => {
                    let value = e.currentTarget.value.toUpperCase();
                    setRemisionPorFacturar((prevState) => {
                      if (!prevState) {
                        return prevState;
                      }
                      return {
                        ...prevState,
                        DatosFacturacion: {
                          ...prevState.DatosFacturacion,
                          Colonia: value,
                        },
                      };
                    });
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </div>

          <div id="Municipio_Estado" className="d-flex gap-3 mt-2">
            <Form.Group className="flex-grow-1">
              <FloatingLabel label="Municipio">
                <Form.Control
                  type="text"
                  placeholder="Municipio"
                  value={remisionPorFacturar.DatosFacturacion.Municipio}
                  onChange={(e) => {
                    let value = e.currentTarget.value.toUpperCase();
                    setRemisionPorFacturar((prevState) => {
                      if (!prevState) {
                        return prevState;
                      }
                      return {
                        ...prevState,
                        DatosFacturacion: {
                          ...prevState.DatosFacturacion,
                          Municipio: value,
                        },
                      };
                    });
                  }}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="flex-grow-1">
              <FloatingLabel label="Estado">
                <Form.Control
                  type="text"
                  placeholder="Estado"
                  value={remisionPorFacturar.DatosFacturacion.Estado}
                  onChange={(e) => {
                    let value = e.currentTarget.value.toUpperCase();
                    setRemisionPorFacturar((prevState) => {
                      if (!prevState) {
                        return prevState;
                      }
                      return {
                        ...prevState,
                        DatosFacturacion: {
                          ...prevState.DatosFacturacion,
                          Estado: value,
                        },
                      };
                    });
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </div>

          <hr />

          <UsoCDFICombo
            SAT_c_UsoCFDI={remisionPorFacturar.DatosFacturacion.SAT_c_UsoCFDI}
            isInvalid={
              validationErrors.findIndex(
                (x) => x.property === "SAT_c_UsoCFDI"
              ) > -1
            }
            onChange={(value) =>
              setRemisionPorFacturar((prevState) => {
                if (!prevState) {
                  return prevState;
                }
                return {
                  ...prevState,
                  DatosFacturacion: {
                    ...prevState.DatosFacturacion,
                    SAT_c_UsoCFDI: value,
                  },
                };
              })
            }
          />

          <FormaPagoCombo
            SAT_c_FormaPago={
              remisionPorFacturar.DatosFacturacion.SAT_c_FormaPago
            }
            isInvalid={
              validationErrors.findIndex(
                (x) => x.property === "SAT_c_FormaPago"
              ) > -1
            }
            onChange={(value) => {
              setRemisionPorFacturar((prevState) => {
                if (!prevState) {
                  return prevState;
                }
                return {
                  ...prevState,
                  DatosFacturacion: {
                    ...prevState.DatosFacturacion,
                    SAT_c_FormaPago: value,
                  },
                };
              });
            }}
          />

          <hr />

          <Form.Group id="eMail" className="mt-2">
            <FloatingLabel label="eMail">
              <Form.Control
                type="text"
                placeholder="eMail"
                isInvalid={
                  validationErrors.findIndex((x) => x.property === "eMail") > -1
                }
                value={remisionPorFacturar.DatosFacturacion.eMail}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  setRemisionPorFacturar((prevState) => {
                    if (!prevState) {
                      return prevState;
                    }
                    return {
                      ...prevState,
                      DatosFacturacion: {
                        ...prevState.DatosFacturacion,
                        eMail: value,
                      },
                    };
                  });
                }}
              />
            </FloatingLabel>
          </Form.Group>

          <InfoModal
            title={infoTitle}
            show={showInfo}
            onClose={() => setShowInfo(false)}
          >
            {infoContent}
          </InfoModal>
        </Form>

        {validationErrors.length > 0 && (
          <Alert variant="danger">
            <ul>
              {validationErrors.map((x, i) => (
                <li key={i}>{x.message}</li>
              ))}
            </ul>
          </Alert>
        )}
        <div className="mb-6 mt-2 d-flex justify-content-end">
          <Button
            className="flex-grow-1"
            variant="primary"
            onClick={handleSave}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FacturarRemision;
