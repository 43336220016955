import { Modal } from "react-bootstrap";

interface InvalidSubscriberErrorMessageProps {
  subscriberId?: string;
  callerUrl?: string;
}

const InvalidSubscriberErrorMessage = ({
  subscriberId,
  callerUrl,
}: InvalidSubscriberErrorMessageProps) => {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          La dirección ingresada{" "}
          {callerUrl && (
            <small>
              <a href={callerUrl.split("?")[0]}>{callerUrl.split("?")[0]}</a>
            </small>
          )}{" "}
          no existe o es incorrecta.
        </p>
        <p>
          Por favor verifica la dirección o ingresa a la página principal de la
          tienda y haz clic en la liga de facturación.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default InvalidSubscriberErrorMessage;
