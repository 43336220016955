import { Button, Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  title: string;
  children?: React.ReactNode;
  onClose: () => void;
}
const InfoModal = ({
  show: showError = true,
  title = "Información",
  children,
  onClose: onClick,
}: Props) => {
  return (
    <Modal show={showError} onHide={onClick}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
