import axios from "axios";
import { getAPIUrl } from "../helpers/apiHelpers";
import { apiError } from "../models/error";
import { Subscriber } from "../models/subscriberModel";

// Singleton per session
let subscriber: Subscriber | undefined;

export const GetSubscriber = async (
  subscriberId: string
): Promise<Subscriber | apiError> => {
  if (subscriber) return subscriber;

  try {
    const response = await axios.get(getAPIUrl(subscriberId));
    return response.data;
  } catch (error) {
    console.log("err", error);
    return { errorMessage: "error" };
  }
};
