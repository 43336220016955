const proxyUrl = process.env.REACT_APP_PROXY_URL;
const proxyCode = process.env.REACT_APP_PROXY_CODE;

export const getAPIUrl = (
  subscriberId: string,
  endpoint: string = "",
  queryString = ""
) => {
  const base = `${proxyUrl}${endpoint}/${subscriberId}?code=${proxyCode}`;
  if (!queryString) return base;

  return `${base}&${queryString}`;
};

export const getGlobalAPIUrl = (endpoint: string = "") => {
  const base = `${proxyUrl}${endpoint}?code=${proxyCode}`;
  return base;
};
