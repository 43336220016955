import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { GetFacturaUrl } from "../api/services/invoiceServices";

interface Props {
  subscriberId: string;
}
const DescargarFactura = ({ subscriberId }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const navigate = useNavigate();

  const folioFactura = searchParams.get("folio");

  const [urlPDF, setUrlPDF] = useState(searchParams.get("urlPDF"));
  const [urlXML, setUrlXML] = useState(searchParams.get("urlXML"));

  const idFactura = searchParams.get("idFactura");

  useEffect(() => {
    async function getFactura() {
      try {
        setUrlXML(
          await GetFacturaUrl(subscriberId, idFactura as string, "XML")
        );
        setUrlPDF(
          await GetFacturaUrl(subscriberId, idFactura as string, "PDF")
        );
      } catch (error) {
        //TODO: Show error
        console.log(error);
        return;
      }
    }

    if (!folioFactura && idFactura) {
      getFactura();
    }
  }, []);

  const handleDownload = (fileName: string) => {
    window.open(fileName, "_blank");
  };
  return (
    <div>
      <div className="panel p-4">
        <span className="fs-3 text-center">Descargar factura</span>
        <span className="mt-4 fs-6 text-center">
          Se ha generado la factura <strong>{folioFactura}</strong>
        </span>
        <div className="mt-4 d-flex justify-content-between">
          {urlPDF && (
            <Button onClick={() => handleDownload(urlPDF)}>
              Descargar PDF
            </Button>
          )}
          {urlXML && (
            <Button onClick={() => handleDownload(urlXML)}>
              Descargar XML
            </Button>
          )}
        </div>

        <div className="mt-4 d-grid gap-2">
          <Button
            variant="success"
            onClick={() => navigate(`/${subscriberId}`)}
          >
            Facturar otra remisión
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DescargarFactura;
