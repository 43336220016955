import axios, { AxiosError } from "axios";
import { getAPIUrl } from "../helpers/apiHelpers";
import {
  facturarRemisionRequest,
  facturarRemisionResponse,
  remisionPorFacturar,
} from "../models/datosFacturacionModel";

import { apiError, validationError } from "../models/error";

/* 
  Valida que se hayan proporcionado el folioRemision, webId y RFC y tengan la estructura correcta
  Las validaciones se realizan de forma local
*/
export const validateRemisionPorFacturarParams = (
  folioRemision: string,
  webId: string,
  rfc: string
): validationError => {
  if (!folioRemision) {
    return {
      valid: false,
      message: "Escriba el folio de la remisión a facturar",
      property: "folioRemision",
    };
  }

  if (!webId) {
    return {
      valid: false,
      message: "Escriba el identificador para facturación de la remisión",
      property: "webId",
    };
  }

  if (webId.length !== 5) {
    return {
      valid: false,
      message: "El identificador para facturación debe ser de 5 caracteres",
      property: "webId",
    };
  }

  if (!rfc) {
    return {
      valid: false,
      message: "Escriba su RFC para facturar",
      property: "rfc",
    };
  }

  if (rfc.length < 12 || rfc.length > 13) {
    return {
      valid: false,
      message: "El RFC debe tener una longitud entre 12 y 13 caracteres",
      property: "rfc",
    };
  }

  return { valid: true };
};

/*
  Pide al API que valide la remision a facturar y obtiene la informacion de la remision
*/
export const getRemisionPorFacturar = async (
  subscriberId: string,
  folioRemision: string,
  webId: string,
  rfc: string
): Promise<remisionPorFacturar> => {
  try {
    const params = new URLSearchParams();
    params.append("folioRemision", folioRemision);
    params.append("webId", webId);
    params.append("rfc", rfc);
    const queryString = params.toString();

    const response = await axios.get(
      getAPIUrl(
        subscriberId,
        "FacturarRemision/GetRemisionPorFacturar",
        queryString
      )
    );

    const r = response.data as remisionPorFacturar;
    console.log("r", r);

    return r;
  } catch (error) {
    console.log("err", error);
    throw error;
  }
};

/*
Valida los datos capturados para facturar la remision
*/
export const validateDatosFacturacion = (
  rem: remisionPorFacturar
): validationError[] => {
  const rValue: validationError[] = [];

  const datos = rem.DatosFacturacion;

  if (!datos.Nombre)
    rValue.push({
      valid: false,
      message: "Escriba el nombre o razón social a la que se facturará",
      property: "Nombre",
    });

  if (!datos.RegimenFiscal)
    rValue.push({
      valid: false,
      message: "Indique su régimen fiscal",
      property: "RegimenFiscal",
    });

  if (!datos.CodigoPostal)
    rValue.push({
      valid: false,
      message: "Indique el código postal de su domicilio fiscal",
      property: "CodigoPostal",
    });

  if (!datos.SAT_c_UsoCFDI)
    rValue.push({
      valid: false,
      message: "Indique el Uso que dará al CFDI",
      property: "SAT_c_UsoCFDI",
    });

  if (!datos.SAT_c_FormaPago)
    rValue.push({
      valid: false,
      message: "Indique la forma de pago",
      property: "SAT_c_FormaPago",
    });

  if (!datos.eMail)
    rValue.push({
      valid: false,
      message: "Indique su correo electrónico para recibir su factura",
      property: "eMail",
    });

  return rValue;
};

/*
Genera la factura de remision
*/
export const CrearFacturaRemision = async (
  remisionPorFacturar: remisionPorFacturar,
  subscriberId: string
): Promise<facturarRemisionResponse> => {
  const request = getFacturarRemisionRequest(remisionPorFacturar);
  try {
    const response = await axios.post(
      getAPIUrl(subscriberId, "FacturarRemision/FacturarRemision"),
      request
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.log(error.response?.data?.Message);
      throw new Error(error.response?.data?.Message);
    } else {
      console.log(error);
      throw new Error("Ocurrió un error al generar la factura");
    }
  }
};

const getFacturarRemisionRequest = (
  remisionPorFacturar: remisionPorFacturar
) => {
  const datos = remisionPorFacturar.DatosFacturacion;

  return {
    RFC: datos.RFC,
    Nombre: datos.Nombre,
    DomicilioCalle: datos.Calle,
    DomicilioNoExterior: datos.NoExterior,
    DomicilioNoInterior: datos.NoInterior,
    DomicilioCodigoPostal: datos.CodigoPostal,
    DomicilioColonia: datos.Colonia,
    DomicilioMunicipio: datos.Municipio,
    DomicilioEstado: datos.Estado,
    DomicilioPais: "",
    c_FormaPago: datos.SAT_c_FormaPago as string,
    c_UsoCFDI: datos.SAT_c_UsoCFDI as string,
    DesglosarIEPS: false,
    eMail: datos.eMail as string,
    Remisiones: [remisionPorFacturar.Remision.idRemision],
    RegimenFiscal: datos.RegimenFiscal,
  };
};

/* Obtiene el URL de xml y pdf para descargar una factura */
export const GetFacturaUrl = async (
  subscriberId: string,
  idFactura: string,
  formato: string
): Promise<string> => {
  const response = await axios.get(
    getAPIUrl(subscriberId, `Factura/${idFactura}/${formato}`)
  );
  return response.data;
};
