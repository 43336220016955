import { useEffect, useState } from "react";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { apiError } from "./api/models/error";
import { Subscriber } from "./api/models/subscriberModel";
import { GetSubscriber } from "./api/services/subscriberServices";
import DatosFacturacionInput from "./components/DatosFacturacionInput";
import RemisionAFacturar from "./pages/RemisionAFacturar";
import InvalidSubscriberErrorMessage from "./components/InvalidSubscriberErrorMessage";
import FacturarRemision from "./pages/FacturarRemision";
import DescargarFactura from "./pages/DescargarFactura";

const App = () => {
  const [subscriber, setSubscriber] = useState<Subscriber>();

  const location = useLocation();
  const baseUrl = location.pathname.split("/")[1];

  useEffect(() => {
    async function getSubs() {
      if (baseUrl) {
        let response = await GetSubscriber(baseUrl);
        if (
          typeof response === "object" &&
          response !== null &&
          "idSuscriptor" in response
        ) {
          const subscriber: Subscriber = response as Subscriber;
          document.title = `SimanG8${String.fromCharCode(
            174
          )} ERP Facturación ${subscriber.nombre}`;
          setSubscriber(subscriber);
        } else {
          const err = response as apiError;
          return;
        }
      }
    }
    getSubs();
  }, []);

  if (!subscriber)
    return (
      <InvalidSubscriberErrorMessage
        subscriberId={baseUrl}
        callerUrl={window.location.href}
      />
    );

  return (
    <div className="App">
      <header className="container d-flex justify-content-center align-items-center flex-wrap">
        <img
          id="subscriber_logo"
          src={subscriber?.logoUrl}
          alt={subscriber?.nombre}
        />
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <div className="fs-2 me-2">{subscriber?.nombre}</div>
          <div className="fs-4 ms-1">| facturación en línea</div>
        </div>
      </header>

      <div className="container d-flex justify-content-center">
        <Routes>
          <Route
            path="/:subscriberId"
            element={
              <RemisionAFacturar subscriberId={subscriber.idSuscriptor} />
            }
          />

          <Route
            path="/:subscriberId/facturarRemision"
            element={
              <FacturarRemision subscriberId={subscriber.idSuscriptor} />
            }
          />
          <Route
            path="/:subscriberId/descargarFactura"
            element={
              <DescargarFactura subscriberId={subscriber.idSuscriptor} />
            }
          />
        </Routes>
      </div>

      <footer className="footer fixed-bottom bg-secondary">
        <div className="container d-flex align-items-center">
          <span className="text-light">Facturación SimanG8&reg; ERP</span>
        </div>
      </footer>
    </div>
  );
};

export default App;
