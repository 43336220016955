import { useEffect, useState } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { RegimenFiscal } from "../../api/models/satModels";
import { getRegimenesFiscales } from "../../api/services/satServices";
import ErrorModal from "../ErrorModal";
import LoadingOverlay from "../LoadingOverlay";

interface Props {
  RegimenFiscal: string | undefined;
  onChange: (regimenFiscal: string) => void;
  isInvalid?: boolean;
}

const RegimenFiscalCombo = (props: Props) => {
  const [regimenesFiscales, setRegimenesFiscales] = useState<RegimenFiscal[]>();
  const [showError, setShowError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    cargarRegimenes();
  }, []);

  const cargarRegimenes = async () => {
    try {
      setShowError(false);
      let regimenesfiscales = await getRegimenesFiscales();
      setRegimenesFiscales(regimenesfiscales);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <LoadingOverlay show={true}>
        <div className="fs-6 text-center">
          <p className="mb-1">Cargando Regímenes fiscales del SAT</p>
          <p className="mb-1">Por favor espere ...</p>
        </div>
      </LoadingOverlay>
    );

  if (showError)
    return (
      <ErrorModal
        buttonText="Reintentar"
        onClick={async () => cargarRegimenes()}
        showError={showError}
      >
        <p>
          Ocurrió un error al obtener el catálogo de Regímenes fiscales del SAT.
        </p>
        <p>Por favor intente de nuevo en unos minutos.</p>
      </ErrorModal>
    );

  return (
    <Form.Group id="RegimenFiscal" className="mt-2">
      <FloatingLabel label="Régimen fiscal">
        <Form.Select
          placeholder="Régimen fiscal"
          isInvalid={props.isInvalid}
          value={props.RegimenFiscal == null ? undefined : props.RegimenFiscal}
          onChange={(e) => props.onChange(e.currentTarget.value)}
        >
          <option>Seleccione su régimen fiscal</option>
          {regimenesFiscales?.map((u, i) => (
            <option key={i} value={u.c_RegimenFiscal}>
              {u.c_RegimenFiscal} {u.descripcion}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Form.Group>
  );
};

export default RegimenFiscalCombo;
